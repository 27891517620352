import { TransportMainController } from "../transport_main_controller";
import BSN from "bootstrap.native";
import Tabulator from "tabulator-tables";

export default class extends TransportMainController {
  static targets = [
    "search", "status", "from_city_ids", "to_city_ids", "start_date", "end_date",
    "table",
    "mapping_dropdown", "mapping_checkbox"
  ]

  connect() {
    console.log("Connect ItinerariesController");
    if (this.hasTableTarget) {
      this._crateTable().then(() => {
        console.log('table was successfully created.');
      }).catch(error => {
        console.error('table Error:', error);
      });
    }
  }

  filterTable(event) {
    if (event.currentTarget === this.searchTarget) {
      if ( event.currentTarget.value.length < 1 && event.currentTarget.value.length !== 0 ) {
        return;
      }
    }

    let filters = this._getPageFilters();

    // Change dataset filtersChanged to true
    super._filtersChangedForTable(this.tableTarget);

    this._filterTable(filters).then(() => {
      console.log(' table was successfully filtered.');
    }).catch(error => {
      console.error(' table filtered Error:', error);
    });

    if (filters.length > 0) {
      this._filtersToHistoryUrl(filters);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }


  //
  //            PRIVATE
  //
  async _filterTable(filters) {
    let tabulator = this.tableTarget.tabulator;
    if (!tabulator) return;

    await this._setFilterToTable(tabulator, filters).then(() => {
      console.log('table was successfully filtered.');
    }).catch(error => {
      console.error('table filtered Error:', error);
    });
  }


  _getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasFrom_city_idsTarget) {
      filters.push({
        field: "from_city_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.from_city_idsTarget)
      })
    }

    if (this.hasTo_city_idsTarget) {
      filters.push({
        field: "to_city_ids",
        type: "=",
        value: super._getSelectCollectionValues(this.to_city_idsTarget)
      })
    }

    if (this.hasStart_dateTarget) {
      filters.push({
        field: "start_date",
        type: "=",
        value: this.start_dateTarget.value
      })
    }

    if (this.hasEnd_dateTarget) {
      filters.push({
        field: "end_date",
        type: "=",
        value: this.end_dateTarget.value
      })
    }

    let statusValues  = [];

    this.statusTargets.forEach((status) => {
      if (status.checked) {
        statusValues.push(status.value);
      }
    });

    if (statusValues.length > 0) {
      filters.push({
        field: "statuses",
        type: "=",
        value: statusValues
      })
    }

    return filters;
  }


  async _crateTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const filters = this._getPageFilters();
    const url = this.data.get("table-load-url");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_itineraries_table_" + lang;

    await this._createItinerariesTable(
      url,
      this.tableTarget,
      persistenceID,
      columns,
      sort_column,
      sort_dir,
      filters
    )
  }

  async _createItinerariesTable(url, table_target, persistenceID, columns, sort_column, sort_dir, filters) {
    let props = {
      columns: columns,
      persistenceID: persistenceID,
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      initialFilter: filters
    }
    //
    let table_props = Object.assign(this._getDefaultTableProps(url), props);

    // create table
    table_target.tabulator = await new Tabulator(table_target, table_props);
    await table_target.tabulator.setLocale(super.appLocale);
  }
}